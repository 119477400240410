var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "collection-item-actions-wrapper" }, [
    _c(
      "div",
      {
        staticClass: "collection-item-actions",
        class: { invisible: _vm.loading }
      },
      [
        _c("Button", {
          attrs: {
            text: "Continue",
            icon: "arrow-right",
            type: "white",
            size: _vm.buttonSize
          },
          on: { click: _vm.handleContinue }
        }),
        _c("UploadDownload", {
          attrs: {
            "props-call": function() {
              return Object.assign({}, _vm.item, {
                name: _vm.item.name + ".pptx"
              })
            },
            "download-callback": _vm.downloadCallback,
            "upload-callback": _vm.uploadCallback
          },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function(ref) {
                var upload = ref.upload
                var download = ref.download
                return [
                  _c("Dropdown", {
                    attrs: {
                      items: (download
                        ? [
                            {
                              text: "Download",
                              icon: "download",
                              callback: function() {
                                return _vm.handleDownload(download)
                              }
                            }
                          ]
                        : []
                      ).concat(
                        upload
                          ? [
                              {
                                text: "Upload to Google Drive",
                                icon: "upload",
                                callback: function() {
                                  return _vm.handleUpload(upload)
                                }
                              }
                            ]
                          : [],
                        [
                          {
                            text: "Duplicate",
                            icon: "copy-medium",
                            callback: _vm.handleDuplicate
                          }
                        ],
                        [
                          {
                            text: "Delete",
                            icon: "bin",
                            callback: _vm.handleDelete,
                            filter: "red"
                          }
                        ]
                      ),
                      "align-top": _vm.dropdownAlignTop
                    },
                    nativeOn: {
                      click: function($event) {
                        $event.stopPropagation()
                      }
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "trigger",
                          fn: function() {
                            return [
                              _c("Button", {
                                attrs: {
                                  icon: "kebab",
                                  type: "grey",
                                  size: _vm.buttonSize
                                },
                                nativeOn: {
                                  click: function($event) {
                                    return _vm.checkAlignment.apply(
                                      null,
                                      arguments
                                    )
                                  }
                                }
                              })
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      true
                    )
                  })
                ]
              }
            }
          ])
        })
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass: "collection-item-actions-loading",
        class: { visible: _vm.loading }
      },
      [
        _c("img", {
          staticClass: "collection-item-actions-loading-icon",
          attrs: { src: require("@/assets/icons/spinner.svg"), alt: "" }
        }),
        _vm._v(" " + _vm._s(_vm.loadingText) + " ")
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }