import { render, staticRenderFns } from "./CollectionModal.vue?vue&type=template&id=a75895cc&scoped=true&"
import script from "./CollectionModal.vue?vue&type=script&lang=js&"
export * from "./CollectionModal.vue?vue&type=script&lang=js&"
import style0 from "./CollectionModal.vue?vue&type=style&index=0&id=a75895cc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a75895cc",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/frontend/frontend/src/web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('a75895cc')) {
      api.createRecord('a75895cc', component.options)
    } else {
      api.reload('a75895cc', component.options)
    }
    module.hot.accept("./CollectionModal.vue?vue&type=template&id=a75895cc&scoped=true&", function () {
      api.rerender('a75895cc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views-v2/collections/CollectionModal.vue"
export default component.exports