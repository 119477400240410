<template>
  <div class="collection-item-actions-wrapper">
    <div class="collection-item-actions" :class="{ invisible: loading }">
      <Button
        text="Continue"
        icon="arrow-right"
        type="white"
        :size="buttonSize"
        @click="handleContinue"
      />
      <UploadDownload
        :props-call="() => ({ ...item, name: `${item.name}.pptx` })"
        :download-callback="downloadCallback"
        :upload-callback="uploadCallback"
      >
        <template #default="{ upload, download }">
          <Dropdown
            :items="[
              ...(download
                ? [
                    {
                      text: 'Download',
                      icon: 'download',
                      callback: () => handleDownload(download)
                    }
                  ]
                : []),
              ...(upload
                ? [
                    {
                      text: 'Upload to Google Drive',
                      icon: 'upload',
                      callback: () => handleUpload(upload)
                    }
                  ]
                : []),
              {
                text: 'Duplicate',
                icon: 'copy-medium',
                callback: handleDuplicate
              },
              {
                text: 'Delete',
                icon: 'bin',
                callback: handleDelete,
                filter: 'red'
              }
            ]"
            :align-top="dropdownAlignTop"
            @click.native.stop
          >
            <template #trigger>
              <Button
                icon="kebab"
                type="grey"
                :size="buttonSize"
                @click.native="checkAlignment"
              />
            </template>
          </Dropdown>
        </template>
      </UploadDownload>
    </div>
    <div class="collection-item-actions-loading" :class="{ visible: loading }">
      <img
        src="@/assets/icons/spinner.svg"
        alt=""
        class="collection-item-actions-loading-icon"
      />
      {{ loadingText }}
    </div>
  </div>
</template>

<script>
import Button from '@c/library/Button.vue'
import Dropdown from '@c/library/Dropdown.vue'
import UploadDownload from '@c/library/UploadDownload.vue'
import { mapActions } from 'vuex'

export default {
  name: 'CollectionItemActions',
  components: { Button, UploadDownload, Dropdown },
  props: {
    item: {
      type: Object,
      required: true
    },
    buttonSize: {
      type: String,
      default: 's'
    }
  },
  data: () => ({
    deleteLoading: false,
    duplicateLoading: false,
    uploadLoading: false,
    downloadLoading: false,
    dropdownAlignTop: false
  }),
  computed: {
    loading() {
      return (
        this.deleteLoading ||
        this.duplicateLoading ||
        this.uploadLoading ||
        this.downloadLoading
      )
    },
    loadingText() {
      let verb = ''
      verb = this.deleteLoading ? 'Deleting' : verb
      verb = this.duplicateLoading ? 'Duplicating' : verb
      verb = this.downloadLoading ? 'Downloading' : verb
      verb = this.uploadLoading ? 'Uploading' : verb
      return `${verb} presentation`
    }
  },
  methods: {
    ...mapActions([
      'deleteCollection',
      'setCurrentCollection',
      'duplicateCollection',
      'setSlideCollectorOpen'
    ]),
    handleContinue() {
      this.setCurrentCollection(this.item)
      this.setSlideCollectorOpen(true)
      this.$router.push({
        name: 'search',
        params: {
          workspace_id: this.$route.params.workspace_id
        }
      })
    },
    async handleDelete() {
      try {
        this.deleteLoading = true
        await this.deleteCollection({
          workspace_id: this.$route.params.workspace_id,
          id: this.item.uuid,
          act_as: this.$route.query.act_as,
          set_current: false
        })
        this.$emit('delete', this.item)
        this.$toast.success('Presentation succesfully deleted')
      } catch (e) {
        this.$toast.error(e, 'deleting presentation')
      } finally {
        this.deleteLoading = false
      }
    },
    async handleDuplicate() {
      try {
        this.duplicateLoading = true
        const collection = await this.duplicateCollection({
          workspace_id: this.$route.params.workspace_id,
          collection_id: this.item.uuid,
          act_as: this.$route.query.act_as
        })
        this.$emit('add', collection)
        this.$toast.success('Presentation duplicated')
      } catch (e) {
        this.$console.error('Error when duplicating presentation', e)
        this.$toast.danger(e, 'duplicating presentation')
      } finally {
        this.duplicateLoading = false
      }
    },
    handleDownload(download) {
      this.downloadLoading = true
      download()
    },
    downloadCallback() {
      this.downloadLoading = false
    },
    handleUpload(upload) {
      this.uploadLoading = true
      upload()
    },
    uploadCallback() {
      this.uploadLoading = false
    },
    checkAlignment(e) {
      this.dropdownAlignTop = e.clientY > window.innerHeight / 2
    }
  }
}
</script>

<style lang="scss" scoped>
.collection-item-actions {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 0.5rem;

  &.invisible {
    opacity: 0;
    pointer-events: none;
  }

  &-wrapper {
    position: relative;
  }

  &-loading {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 0.5rem;
    opacity: 0;
    width: 0;
    overflow: hidden;

    &.visible {
      opacity: 1;
      width: 100%;
      overflow: visible;
    }

    &-icon {
      filter: brightness(0) saturate(100%) invert(25%) sepia(95%)
        saturate(2915%) hue-rotate(217deg) brightness(97%) contrast(103%);
      animation: spin 1s linear infinite;
    }
  }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
</style>
