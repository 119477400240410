<template>
  <div class="collections">
    <div class="collections-top">
      <TextInput
        v-model="query"
        left-icon="search"
        button-icon="close"
        placeholder="Search presentations..."
        class="collections-top-search"
        :disabled="consumptionLoading || collectionsEmpty"
        @submit="handleClear"
      />
    </div>
    <div ref="collectionswrapper" class="collections-wrapper">
      <Table
        :key="tableKey"
        :items="filteredCollections"
        :headers="headers"
        :clickable="true"
        :max-height="maxHeightString"
        :height="maxHeightString"
        default-sort-by="date_updated"
        @select="modalItem = $event"
      >
        <template #item="{ item, header }">
          <div v-if="header.id === 'name'" class="collections-table-item-name">
            <img
              v-if="itemPreview(item)"
              :src="itemPreview(item)"
              alt=""
              class="collections-table-item-preview"
            />
            <div
              v-if="editNameId !== item.uuid"
              class="collections-table-item-name-view"
            >
              <p class="collections-table-item-name-view-text">
                {{ item.name }}
              </p>
              <Button
                icon="edit"
                type="white"
                size="xs"
                class="collections-table-item-name-view-button"
                @click.native.stop="() => handleEditName(item)"
              />
            </div>
            <TextInput
              v-else
              v-model="editName"
              button-icon="check-medium"
              class="collections-table-item-name-edit"
              @submit="handleSaveName"
              @blur="handleSaveName"
              @click.native.stop
            />
          </div>
          <CollectionItemActions
            v-else-if="header.id === 'buttons'"
            :item="item"
            @delete="handleDelete"
            @add="handleAdd"
          />
        </template>
      </Table>
      <div v-if="consumptionLoading" class="collections-loading">
        <b-loading active :is-full-page="false" />
      </div>
      <div v-else-if="collectionsEmpty" class="collections-empty">
        <span class="collections-empty-title"
          >No saved presentations found</span
        >
        <div class="collections-empty-sub">
          <span
            >Once you collect slides in the content search and save or
            export</span
          >
          <span>you can find the newly created presentation here</span>
        </div>
        <img
          class="collections-empty-icon"
          src="@/assets/icons/slide-selection.svg"
        />
      </div>
    </div>
    <CollectionModal
      :visible="modalItem !== undefined"
      :collection="modalItem"
      @close="modalItem = undefined"
    />
  </div>
</template>

<script>
import { PaginatorConsumer } from '@/mixins/PaginatorConsumer'
import Table from '@c/library/Table.vue'
import TextInput from '@c/library/TextInput.vue'
import { formatDateWTime } from '@/util'
import { mapActions, mapGetters } from 'vuex'
import Button from '@c/library/Button.vue'
import CollectionItemActions from './collections/CollectionItemActions.vue'
import CollectionModal from './collections/CollectionModal.vue'
import { MaxHeightMixin } from '@/mixins/MaxHeightMixin'

export default {
  name: 'Collections',
  components: {
    TextInput,
    Table,
    Button,
    CollectionItemActions,
    CollectionModal
  },
  mixins: [PaginatorConsumer, MaxHeightMixin],
  data: () => ({
    query: '',
    editNameId: '',
    editName: '',
    tableKey: 0,
    modalItem: undefined
  }),
  computed: {
    ...mapGetters(['showCollectSlides']),
    collections() {
      return this.paginatorData || []
    },
    collectionsEmpty() {
      return !this.consumptionLoading && !this.paginatorData?.length
    },
    filteredCollections() {
      return this.query
        ? this.collections.filter((collection) =>
            collection.name.toLowerCase().includes(this.query.toLowerCase())
          )
        : this.collections
    },
    loadingOrDone() {
      return this.consumptionLoading || this.isDone
    },
    headers() {
      return [
        {
          id: 'name',
          title: 'Name',
          key: (collection) => collection.name,
          sortAsc: (a, b) => a.name.localeCompare(b.name),
          sortDesc: (a, b) => b.name.localeCompare(a.name)
        },
        {
          id: 'source',
          title: 'Source',
          key: (collection) =>
            collection.story ? 'Generated pitch' : 'Collected slides',
          sortAsc: (a, b) => new Number(!!a.story) - new Number(!!b.story),
          sortDesc: (a, b) => new Number(!!b.story) - new Number(!!a.story)
        },
        {
          id: 'date_updated',
          title: 'Updated',
          key: (collection) => formatDateWTime(collection.date_updated),
          sortAsc: (a, b) => {
            const aDate = new Date(a.date_updated)
            const bDate = new Date(b.date_updated)
            return aDate - bDate
          },
          sortDesc: (a, b) => {
            const aDate = new Date(a.date_updated)
            const bDate = new Date(b.date_updated)
            return bDate - aDate
          }
        },
        {
          id: 'date_created',
          title: 'Created',
          key: (collection) => formatDateWTime(collection.date_created),
          sortAsc: (a, b) => {
            const aDate = new Date(a.date_created)
            const bDate = new Date(b.date_created)
            return aDate - bDate
          },
          sortDesc: (a, b) => {
            const aDate = new Date(a.date_created)
            const bDate = new Date(b.date_created)
            return bDate - aDate
          }
        },
        {
          id: 'buttons',
          title: '',
          key: () => '',
          sortAsc: () => 0,
          sortDesc: () => 0
        }
      ]
    }
  },
  created() {
    if (!this.showCollectSlides) this.$router.push({ name: 'home' })
  },
  methods: {
    ...mapActions(['getCollectionsPaginator', 'editCollection']),
    getMaxHeightElement() {
      return this.$refs.collectionswrapper
    },
    async getPaginator() {
      // TODO: add infinite scroll & initial load to fill screen
      return await this.getCollectionsPaginator({
        workspace_id: this.$route.params.workspace_id,
        search: this.query,
        page_size: 999,
        act_as: this.$route.query.act_as
      })
    },
    handleInfiniteScroll() {
      this.loadPage()
    },
    handleClear() {
      if (this.query) {
        this.query = ''
        this.resetPaginatorConsumer()
      }
    },
    refreshTable() {
      this.tableKey++
    },
    itemPreview(item) {
      return item?.pages?.[0]?.signed_url || ''
    },
    async handleSaveName() {
      try {
        const idx = this.paginatorData.findIndex(
          (collection) => collection.uuid === this.editNameId
        )
        if (idx !== -1 && this.editName !== this.paginatorData[idx].name) {
          const newItem = await this.editCollection({
            workspace_id: this.$route.params.workspace_id,
            id: this.editNameId,
            name: this.editName,
            act_as: this.$route.query.act_as
          })
          this.paginatorData[idx] = newItem
          this.tableKey++
          this.$toast.success('Name succesfully saved')
        }
        this.editNameId = ''
        this.editName = ''
      } catch (e) {
        this.$toast.error(e, 'changing the name of the presentation')
      }
    },
    handleEditName(item) {
      this.editNameId = item.uuid
      this.editName = item.name
    },
    handleDelete(collection) {
      this.paginatorData = this.paginatorData.filter(
        (item) => item.uuid !== collection.uuid
      )
      this.refreshTable()
    },
    handleAdd(collection) {
      this.paginatorData.push(collection)
      this.refreshTable()
    }
  }
}
</script>

<style lang="scss" scoped>
.collections {
  &-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2.5rem;

    &-search {
      width: min(100%, 30rem);
    }
  }

  &-wrapper {
    padding: 0 2.5rem;
  }

  &-table-item {
    &-preview {
      width: 5rem;
      min-width: 5rem;
      border-radius: 4px;
      border: 1px solid rgba(#000, 0.08);
    }

    &-name {
      width: 100%;
      max-width: 25rem;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: 1.5rem;

      &:hover {
        & .collections-table-item-name-view-button {
          opacity: 1;
        }
      }

      &-view {
        display: flex;
        align-items: center;
        gap: 1rem;

        &-text {
          max-width: 17.5rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        &-button {
          opacity: 0;
          transition: opacity 0.2s ease;
        }
      }

      &-edit {
        flex: 1;
      }
    }

    &-btns {
      display: flex;
      align-items: center;
      flex-flow: row nowrap;
      gap: 1rem;
    }
  }

  &-loading {
    position: relative;
    min-height: 5rem;
  }

  &-empty {
    width: 100%;
    padding: 1rem 2.5rem;
    display: flex;
    flex-flow: column;
    gap: 0.75rem;
    align-items: center;

    &-title {
      font-weight: 700;
      font-size: 1.1rem;
    }

    &-sub {
      display: flex;
      flex-flow: column;
      align-items: center;
      color: #8f9399;
    }

    &-icon {
      height: 4rem;
    }
  }
}
</style>
