<template>
  <b-modal :active="visible" @close="$emit('close')">
    <div class="collection-modal">
      <div class="collection-modal-header">
        <p class="collection-modal-header-title">{{ title }}</p>
        <p class="collection-modal-header-subtitle">
          <img
            src="@/assets/icons/slides.svg"
            alt=""
            class="collection-modal-header-subtitle-icon"
          />
          <span>{{ subtitle }}</span>
          <span>{{ updateText }}</span>
        </p>
        <div class="collection-modal-header-btns">
          <CollectionItemActions :item="collection" button-size="m" />
        </div>
      </div>
      <SlideDeck :items="pages" preview-key="signed_url" />
    </div>
  </b-modal>
</template>

<script>
import { formatDate } from '@/util'
import CollectionItemActions from './CollectionItemActions.vue'
import SlideDeck from '@c/library/SlideDeck.vue'

export default {
  name: 'CollectionModal',
  components: { CollectionItemActions, SlideDeck },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    collection: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    pages() {
      return this.collection?.pages || []
    },
    title() {
      return this.collection?.name || ''
    },
    subtitle() {
      return `${this.pages.length} collected slide${
        this.pages.length === 1 ? '' : 's'
      }.`
    },
    updated() {
      return this.collection?.date_updated || ''
    },
    created() {
      return this.collection?.date_created || ''
    },
    hasUpdate() {
      return (
        this.updated &&
        (!this.created || new Date(this.updated) > new Date(this.created))
      )
    },
    updateText() {
      return this.hasUpdate
        ? `Last updated on ${formatDate(this.updated)}`
        : `Created on ${formatDate(this.created)}`
    }
  }
}
</script>

<style lang="scss" scoped>
.collection-modal {
  background: white;
  border-radius: 8px;
  border: 1px solid rgba(#000, 0.08);
  padding: 1.5rem;
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;

  &-header {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto auto;
    grid-template-areas:
      'title btns'
      'subtitle btns';
    align-items: center;
    gap: 0.25rem 1rem;

    &-title {
      grid-area: title;
      font-size: 1.25rem;
      font-weight: 700;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &-subtitle {
      grid-area: subtitle;
      color: #8f9399;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: 0.5rem;

      &-icon {
        filter: invert(70%) sepia(11%) saturate(185%) hue-rotate(177deg)
          brightness(83%) contrast(80%);
        height: 1rem;
      }
    }

    &-btns {
      grid-area: btns;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: 0.5rem;
    }
  }
}
</style>
