var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: { active: _vm.visible },
      on: {
        close: function($event) {
          return _vm.$emit("close")
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "collection-modal" },
        [
          _c("div", { staticClass: "collection-modal-header" }, [
            _c("p", { staticClass: "collection-modal-header-title" }, [
              _vm._v(_vm._s(_vm.title))
            ]),
            _c("p", { staticClass: "collection-modal-header-subtitle" }, [
              _c("img", {
                staticClass: "collection-modal-header-subtitle-icon",
                attrs: { src: require("@/assets/icons/slides.svg"), alt: "" }
              }),
              _c("span", [_vm._v(_vm._s(_vm.subtitle))]),
              _c("span", [_vm._v(_vm._s(_vm.updateText))])
            ]),
            _c(
              "div",
              { staticClass: "collection-modal-header-btns" },
              [
                _c("CollectionItemActions", {
                  attrs: { item: _vm.collection, "button-size": "m" }
                })
              ],
              1
            )
          ]),
          _c("SlideDeck", {
            attrs: { items: _vm.pages, "preview-key": "signed_url" }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }