var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "collections" },
    [
      _c(
        "div",
        { staticClass: "collections-top" },
        [
          _c("TextInput", {
            staticClass: "collections-top-search",
            attrs: {
              "left-icon": "search",
              "button-icon": "close",
              placeholder: "Search presentations...",
              disabled: _vm.consumptionLoading || _vm.collectionsEmpty
            },
            on: { submit: _vm.handleClear },
            model: {
              value: _vm.query,
              callback: function($$v) {
                _vm.query = $$v
              },
              expression: "query"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { ref: "collectionswrapper", staticClass: "collections-wrapper" },
        [
          _c("Table", {
            key: _vm.tableKey,
            attrs: {
              items: _vm.filteredCollections,
              headers: _vm.headers,
              clickable: true,
              "max-height": _vm.maxHeightString,
              height: _vm.maxHeightString,
              "default-sort-by": "date_updated"
            },
            on: {
              select: function($event) {
                _vm.modalItem = $event
              }
            },
            scopedSlots: _vm._u([
              {
                key: "item",
                fn: function(ref) {
                  var item = ref.item
                  var header = ref.header
                  return [
                    header.id === "name"
                      ? _c(
                          "div",
                          { staticClass: "collections-table-item-name" },
                          [
                            _vm.itemPreview(item)
                              ? _c("img", {
                                  staticClass: "collections-table-item-preview",
                                  attrs: { src: _vm.itemPreview(item), alt: "" }
                                })
                              : _vm._e(),
                            _vm.editNameId !== item.uuid
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "collections-table-item-name-view"
                                  },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "collections-table-item-name-view-text"
                                      },
                                      [_vm._v(" " + _vm._s(item.name) + " ")]
                                    ),
                                    _c("Button", {
                                      staticClass:
                                        "collections-table-item-name-view-button",
                                      attrs: {
                                        icon: "edit",
                                        type: "white",
                                        size: "xs"
                                      },
                                      nativeOn: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return function() {
                                            return _vm.handleEditName(item)
                                          }.apply(null, arguments)
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _c("TextInput", {
                                  staticClass:
                                    "collections-table-item-name-edit",
                                  attrs: { "button-icon": "check-medium" },
                                  on: {
                                    submit: _vm.handleSaveName,
                                    blur: _vm.handleSaveName
                                  },
                                  nativeOn: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                    }
                                  },
                                  model: {
                                    value: _vm.editName,
                                    callback: function($$v) {
                                      _vm.editName = $$v
                                    },
                                    expression: "editName"
                                  }
                                })
                          ],
                          1
                        )
                      : header.id === "buttons"
                      ? _c("CollectionItemActions", {
                          attrs: { item: item },
                          on: { delete: _vm.handleDelete, add: _vm.handleAdd }
                        })
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm.consumptionLoading
            ? _c(
                "div",
                { staticClass: "collections-loading" },
                [
                  _c("b-loading", {
                    attrs: { active: "", "is-full-page": false }
                  })
                ],
                1
              )
            : _vm.collectionsEmpty
            ? _c("div", { staticClass: "collections-empty" }, [
                _c("span", { staticClass: "collections-empty-title" }, [
                  _vm._v("No saved presentations found")
                ]),
                _vm._m(0),
                _c("img", {
                  staticClass: "collections-empty-icon",
                  attrs: { src: require("@/assets/icons/slide-selection.svg") }
                })
              ])
            : _vm._e()
        ],
        1
      ),
      _c("CollectionModal", {
        attrs: {
          visible: _vm.modalItem !== undefined,
          collection: _vm.modalItem
        },
        on: {
          close: function($event) {
            _vm.modalItem = undefined
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "collections-empty-sub" }, [
      _c("span", [
        _vm._v(
          "Once you collect slides in the content search and save or export"
        )
      ]),
      _c("span", [_vm._v("you can find the newly created presentation here")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }